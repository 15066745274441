<template>
	<v-container id="user-profile-view" fluid tag="section">
		<v-row justify="center">
			<v-col cols="12" md="8">
				<v-card>
					<template #title> Edit Profile — <small class="text-body-1">Complete your profile</small> </template>

					<v-form>
						<v-container class="py-0">
							<v-row>
								<v-col cols="12" md="6">
									<v-text-field v-model="userDetails.name" label="Full Name" />
								</v-col>

								<v-col cols="12" md="6">
									<v-text-field v-model="userDetails.date" label="Last login details" />
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card>
			</v-col>

			<!-- <v-col cols="12" md="4">
				<v-card class="mt-4 text-center">
					<v-img
						class="rounded-circle elevation-6 mt-n12 d-inline-block"
						src="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
						width="128"
					/>
					<v-card-text class="text-center">
						<h6 class="text-h6 mb-2 text--secondary">CEO / FOUNDER</h6>

						<h4 class="text-h4 mb-3 text--primary">John Leider</h4>

						<p class="text--secondary">
							Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione dolorem deserunt veniam tempora magnam
							quisquam quam error iusto cupiditate ducimus, et eligendi saepe voluptatibus assumenda similique
							temporibus placeat animi dicta?
						</p>

						<v-btn class="mr-0" color="primary" min-width="100" rounded> Follow </v-btn>
					</v-card-text>
				</v-card>
			</v-col> -->
		</v-row>
	</v-container>
</template>

<script>
	import store from '@/store'
	export default {
		setup() {
			return {
				userDetails: store ? store.state.user.currentUser : null,
			}
		},
		name: 'UserProfileView',
	}
</script>
